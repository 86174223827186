import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { saveFile, transformSelectMenuValue, transformSelectValues, transformSelectValuesForPhonePrefixes, transformUerTypeListSelectValues } from "../../utils/helpers.utils";
import { UsersState } from "./usersSlice.types";
import AppConstants from "../../constants";

export const generateUsername = createAsyncThunk(
  "Users/GenerateUsername",
  async ({
    params
  }: {
    params: any;
  }, { rejectWithValue }) => {
    try {
      const request = {
        url: `user/check-username-validity?${params.queryString}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAddUserDropdownValues = createAsyncThunk(
  "Users/FetchAddUserDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `user/populate`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsersByFilter = createAsyncThunk(
  "Users/FetchByFilter",
  async (
    {
      filters,
      requestContinuationToken
    }: {
      requestContinuationToken?: string;
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'users/search/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      if (requestContinuationToken && filters.page) {
        request.headers = {
          'x-requestcontinuation-token': requestContinuationToken
        }
      }
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserInUaeService = createAsyncThunk(
  "Users/CreateUserInUaeService",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      try {
        const countryRequest = {
          url: 'country/url',
          method: EApiMiddlewareMethods.GET,
        } as any;
        countryRequest.headers = {
          "countryISOCode": payload.countryDetails.code,
        };
        const { data: countryData } = await networkHandler(countryRequest, false, true, false, true);
        sessionStorage.setItem(AppConstants.COUNTRY_URL, countryData.countryUrl);
      } catch (error : any) {
        return rejectWithValue(error.response.data);
      }
      const request = {
        url: 'assignee/register',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCountryUrl = createAsyncThunk(
  "Users/FetchCountryUrl",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const countryRequest = {
        url: 'country/url',
        method: EApiMiddlewareMethods.GET,
      } as any;
      countryRequest.headers = {
        "countryISOCode": payload,
      };
      const { data: countryData } = await networkHandler(countryRequest, false, true, false, true);
      sessionStorage.setItem(AppConstants.COUNTRY_URL, countryData.countryUrl);
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserInUaeService = createAsyncThunk(
  "Users/UpdateUserInUaeService",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/update',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserViaSA = createAsyncThunk(
  "Users/CreateUserViaSA",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/create',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkUpdateUsers = createAsyncThunk(
  "Users/BulkUpdateUsers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/bulkapproval',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "Users/updateUser",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/update',
        method: EApiMiddlewareMethods.PUT,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const usersResetPwd = createAsyncThunk(
  "Users/ResetPassword",
  async (
    {
      userdata
    }: {
      userdata: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/forgotPassword',
        method: EApiMiddlewareMethods.POST,
        data: userdata
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const endActiveSession = createAsyncThunk(
  "Users/EndActiveSession",
  async (
    {
      driverId
    }: {
      driverId: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'authenticate/invalidate/deviceId',
        method: EApiMiddlewareMethods.GET,
      } as any;
      if (driverId) {
        request.headers = {
          'user-id': driverId
        }
      }
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearList = createAsyncThunk("Users/ClearContents", () => {
  return [];
});

export const resetUsersResetPwd = createAsyncThunk("Users/ResetUserResetPassword", () => {
  return false;
});

export const resetUsersError = createAsyncThunk("Users/ResetError", () => {
  return "";
});

export const resetUserCreationStatus = createAsyncThunk("Users/ResetUserCreationStatus", () => {
  return false;
});

export const resetUserUpdationStatus = createAsyncThunk("Users/ResetUserUpdationStatus", () => {
  return false;
});

export const setLoggedInUser = createAsyncThunk("Users/SetLoggedInUser", () => {
  return false;
});

export const exportUserInformationToExcel = createAsyncThunk("Users/exportUserInformationToExcel", async ({payload}: {payload: any}, { rejectWithValue }) => {
  try {
    const request = {
      url: 'v1/export/users',
      method: EApiMiddlewareMethods.POST,
      data: payload,
      responseType: "blob"
    } as any;
    const { data } = await networkHandler(request, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const bulkDeleteUser = createAsyncThunk("Users/bulkDeleteUser", async ({payload}: {payload: any}, { rejectWithValue }) => {
  try {
    const request = { url: 'user/delete', method: EApiMiddlewareMethods.DELETE, data: payload } as any;
    const { data } = await networkHandler(request, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchUsersBuildVersion = createAsyncThunk("Users/fetchUsersBuildVersion", async ({filters, requestContinuationToken}: {requestContinuationToken?: string; filters: any; }, { rejectWithValue }) => {
    try {
      const request = { url: 'users/search/dynamic/filter', method: EApiMiddlewareMethods.POST, data: filters } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: UsersState = {
  loading: false,
  tableLoading: false,
  userUpdateStatus: false,
  userCreationStatus: false,
  userCreationMsg: "",
  userRegisteredStatus: false,
  resetPwdSuccess: false,
  suggestedUsername: "",
  userUpdateMsg: "",
  endSession: false,
  addUserDropdowns: {
    countriesList: [],
    hubList: [],
    rolesList: [],
    userTypeList: [],
    statusList: [],
    deliveryTypeList: [],
    vehicleTypeList: [],
    vendorList: [],
    serviceTypeList: [],
    vendorListStd: [],
    buildVersionList: [],
    phonePrefixesList: []
  },
  data: { content: [], pageable: { pageNumber: 0 }},
  error: "",
  resetPwdMsg: ""
};

const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(generateUsername.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.suggestedUsername = "";
      })
      .addCase(generateUsername.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.suggestedUsername = payload.suggestions && payload.suggestions.length ? payload.suggestions[0] : "";
      })
      .addCase(generateUsername.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAddUserDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAddUserDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          let countriesList: any = {};
          let hubList: any = {};
          let vendorList: any = {};
          let vendorListStd: any = {};
          let phonePrefixesList: any = [];
          if (payload.roleCountryMap) {
            Object.keys(payload.roleCountryMap).filter((role: any) => {
              countriesList[role] = transformSelectValues(payload.roleCountryMap[role]);
              return true;
            });
          }
          if (payload.countryHubMap) {
            Object.keys(payload.countryHubMap).filter((country: any) => {
              hubList[country] = transformSelectValues(payload.countryHubMap[country]);
              return true;
            });
          }
          if (payload.expressCountryVendorMap) {
            Object.keys(payload.expressCountryVendorMap).filter((country: any) => {
              vendorList[country] = transformSelectValues(payload.expressCountryVendorMap[country]);
              return true;
            });
          }
          if (payload.standardCountryVendorMap) {
            Object.keys(payload.standardCountryVendorMap).filter((country: any) => {
              vendorListStd[country] = transformSelectValues(payload.standardCountryVendorMap[country]);
              return true;
            });
          }
          if(payload?.phonePrefixes?.length > 0){
            phonePrefixesList = transformSelectValuesForPhonePrefixes(payload.phonePrefixes);
          }
          state.errorCode = "";
          state.error = "";
          state.addUserDropdowns = {
            ...state.addUserDropdowns,
            countriesList: countriesList,
            rolesList: transformSelectValues(payload.roles),
            userTypeList: transformUerTypeListSelectValues(payload.userTypes),
            statusList: transformSelectValues(payload.userStatus),
            hubList: hubList,
            deliveryTypeList: transformSelectValues(payload.deliveryTypes),
            vehicleTypeList: transformSelectValues(payload.vehicleTypes),
            vendorList: vendorList,
            vendorListStd: vendorListStd,
            serviceTypeList: transformSelectValues(payload.serviceTypes),
            phonePrefixesList: phonePrefixesList
          };
        }
      })
      .addCase(fetchAddUserDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.addUserDropdowns = {
          countriesList: {},
          rolesList: [],
          userTypeList: [],
          statusList: [],
          hubList: {},
          deliveryTypeList: [],
          vehicleTypeList: [],
          vendorList: [],
          serviceTypeList: [],
          vendorListStd: [],
          buildVersionList: [],
          phonePrefixesList: []
        };
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(clearList.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          content: action.payload,
        };
      })
      .addCase(fetchUsersByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchUsersByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.data = {
            ...payload,
            content:
              filters.page === 0
                ? [...payload.content]
                : [...state.data.content, ...payload.content],
          };
        }
      })
      .addCase(fetchUsersByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(createUserViaSA.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createUserViaSA.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.suggestedUsername = "";
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userCreationStatus = true;
          state.userCreationMsg = `User ${payload.firstName} ${payload.lastName} has been added successfully`;
        }
      })
      .addCase(createUserViaSA.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userCreationStatus = false;
        state.userCreationMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(createUserInUaeService.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createUserInUaeService.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.suggestedUsername = "";
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userRegisteredStatus = true;
        }
      })
      .addCase(createUserInUaeService.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userRegisteredStatus = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(bulkUpdateUsers.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(bulkUpdateUsers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.tableLoading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userUpdateStatus = true;
          state.userUpdateMsg = payload;
        }
      })
      .addCase(bulkUpdateUsers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.userUpdateStatus = false;
        state.userUpdateMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.userUpdateStatus = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.suggestedUsername =  "";
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userUpdateStatus = true;
          state.userUpdateMsg = payload;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userUpdateStatus = false;
        state.userUpdateMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(usersResetPwd.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.resetPwdSuccess = false;
      })
      .addCase(usersResetPwd.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.loading = false;
        if (payload.error) {
          state.resetPwdSuccess = false;
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.resetPwdSuccess = true;
          state.resetPwdMsg = payload?.message;
        }
      })
      .addCase(usersResetPwd.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.resetPwdSuccess = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetUsersResetPwd.fulfilled, (state, action) => {
        const { payload } = action;
        state.resetPwdSuccess = payload;
      })
      .addCase(resetUsersError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(resetUserCreationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.userCreationStatus = payload;
      })
      .addCase(resetUserUpdationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.userUpdateStatus = payload;
      })
      .addCase(endActiveSession.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(endActiveSession.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload) {
          state.endSession = payload;
        }
      })
      .addCase(endActiveSession.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** exportUserInformationToExcel */
      .addCase(exportUserInformationToExcel.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.errorCode = "";
      })
      .addCase(exportUserInformationToExcel.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        if (payload) {
          let filename = `User_Info.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportUserInformationToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** bulkDeleteUser */
      .addCase(bulkDeleteUser.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(bulkDeleteUser.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
      })
      .addCase(bulkDeleteUser.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchUsersBuildVersion */
      .addCase(fetchUsersBuildVersion.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.addUserDropdowns.buildVersionList = [];
      })
      .addCase(fetchUsersBuildVersion.fulfilled, (state, action) => {
        const { payload, meta: { arg: { filters }}} = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload && payload.message ? payload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        }else if(payload?.buildVersion?.length > 0) {
          state.addUserDropdowns.buildVersionList = transformSelectMenuValue(payload.buildVersion);
        }
      })
      .addCase(fetchUsersBuildVersion.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload && errorPayload.message ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
  },
});

export default UsersSlice.reducer;
